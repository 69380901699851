.top {
  width: 100%;
  height: 120px;
  background-color: #e4d8b4;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topCenter {
  flex: 6;
}

.topList {
  background-color: transparent;
  display: flex;
  height: 70px;
  justify-content: center;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  list-style: none;
}
.topListItem {
  display: grid;
  background-color: transparent;
  margin-right: 45px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}
.topListItem:hover {
  text-shadow: 10px 20px 30px rgba(76, 76, 76, 0.5);
}

.topIcon {
  background-color: transparent;
  font-size: 35px;
  margin-left: 25px;
  color: #444;
  cursor: pointer;
}
.icon-title {
  color: #444;
  font-family: "Gravitas One", system-ui;
  font-weight: 400;
}

.topSearchIcon {
  font-size: 18px;
  color: #444;
  cursor: pointer;
  margin-right: 30%;
}
.link {
  text-decoration: none;
}
.search {
  border-radius: 9px;
  border: 1px solid #444;
  width: 130px;
  margin-right: 10px;
}
