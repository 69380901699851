.bottom {
  align-items: flex-start;
  background-color: #e4d8b4;
  bottom: 0;
  border-top: 1px solid #e4d8b4;
  display: flex;
  height: 25px;
  left: 0;
  margin-left: 15px;
  padding: 3px 10px 0px 10px;
  position: fixed;
  width: 100%;
  text-align: left;
  z-index: 999;
}
.bottom-text-box {
  color: #444;
  font-family: "Montserrat", system-ui;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}

.bottom-text {
  color: #444;
}
