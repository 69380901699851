.container {
  display: flex;
  height: 100vh; /* Full viewport height */
  margin-bottom: 10px;
}
.case-title {
  color: #444;
  font-family: "Gravitas One", system-ui;
  line-height: 50px;
  font-size: 40px;
  font-weight: 400;
  position: sticky absolute;
}
.date-box {
  margin: 10px;
}
.date-container {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
}
.date-section {
  font-family: "Montserrat", system-ui;
  font-size: 15px;
  font-weight: 600;
}
.section {
  font-family: "Montserrat", system-ui;
  font-size: 20px;
  font-weight: 600;
}
.p-section {
  font-family: "Montserrat", system-ui;
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 10px;
}
.left-column {
  border-right: 1px solid #e3d5a4;
  flex: 1.6;
  max-width: 100%;
  padding: 40px 20px 100px 50px;
  margin-left: 3em;
  top: 0;
}

.right-column {
  flex: 3;
  overflow-y: auto;
  margin-right: 3em;
  max-width: 100%;
  max-height: 100vh;
  padding: 40px 50px 20px 50px;
}
