.home {
  display: flex;
}
.header {
  margin-top: 100px;
  background-color: #e4d8b4;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #444;
}

.headerTitleSmTop {
  top: 18%;
  font-family: "Montserrat", system-ui;
  font-size: 20px;
  font-weight: 500;
}
.headerTitleSmBottom {
  display: flex;
  top: 22%;
  font-family: "Montserrat", system-ui;
  font-size: 20px;
  font-weight: 500;
}
.bottomTitleSubFont {
  font-family: "Gravitas One", system-ui;
  margin-left: 8px;
}
.headerTitleLg {
  top: 20%;
  font-family: "Gravitas One", system-ui;
  font-size: 100px;
}

.headerImg {
  width: 100%;
  height: 450px;
  margin-top: 80px;
  object-fit: cover;
}
