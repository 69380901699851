.post {
  border-bottom: 1px solid #444;
  width: 385px;
  margin: 0px 25px 40px 35px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  cursor: pointer;
}
.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.postTitle {
  color: #000000;
  font-family: "Montserrat", system-ui;
  font-size: 22px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
}
.postDate {
  font-family: "Montserrat", system-ui;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  margin-top: 10px;
}
.postDesc {
  font-family: "Varela Round", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #444444;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
